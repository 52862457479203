// src/components/Gallery.js

import React, { useEffect, useState } from "react";
import "./Gallery.css";
import one from "../Assets/aquahomestay.jpeg";
import two from "../Assets/aqualillies.jpeg";
import three from "../Assets/bedhomestay.jpeg";
import four from "../Assets/front lakehouse.jpeg";
import five from "../Assets/homestay.jpeg";
import six from "../Assets/homestayaqua.jpeg";
import seven from "../Assets/lake.jpeg";
import eight from "../Assets/roombedhomestay.jpeg";
import axios from "axios";

const Gallery = () => {
  const [gallery, setGallery] = useState([]);
  const galleryImages = [one, two, three, four, five, six, seven, eight];
  useEffect(() => {
    Gallery();
  }, []);
  const Gallery = () => {
    try {
      axios({
        url: "https://lunarsenterprises.com:6020/aqualillies/list/gallery",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.data.result) {
            setGallery(response.data.list);
          } else {
            console.log("not working in testimonials list");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err, "error");
    }
  };
  const baseurl = "https://lunarsenterprises.com:6020";
  return (
    <div className="gallery">
      <h2>Our Gallery</h2>
      <div className="gallery-grid">
        {gallery.map((image, index) => (
          <div key={index} className="gallery-item">
            <img src={baseurl + image.g_image} alt={`Gallery ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
