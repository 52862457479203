import React from "react";
import "./Dashboard.css";
import NavigationBar from "./Navbar";
import ImageSlider from "./Slider";
import Content from "./Content";
import Activities from "./Activities";
import Gallery from "./Gallery";
import Testimonials from "./Testimonials";
import Footer from "./Footer";
import { MdMail } from "react-icons/md";
import { BiPhone } from "react-icons/bi";

const Dashboard = () => {
  return (
    <div>
      <div className="above-header">
        <a href="tel:+97 152 690 2530">
          <div>
            <BiPhone /> Phone: +91 9847968677
          </div>
        </a>
        <a href="mailto:kumarakomaqualillies@gmail.com">
          <div>
            {" "}
            <MdMail /> Email: kumarakomaqualillies@gmail.com
          </div>
        </a>
      </div>
      <NavigationBar />
      <div style={{ padding: "0px 0px" }}>
        <ImageSlider />
        <Activities />
        <Content />
        <Gallery />
        <Testimonials />
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
