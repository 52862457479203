import React, { useState } from "react";
import "./Contact.css";
import "./Dashboard.css";
import NavigationBar from "./Navbar";
import Footer from "./Footer";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BiPhone } from "react-icons/bi";
import { MdMail } from "react-icons/md";

const Contact = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    message: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios({
        url: "https://lunarsenterprises.com:6020/aqualillies/contactus",
        method: "POST",
        data: {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          message: formData.message,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === true) {
        toast.success(
          response.data.message || "Enquiry submitted successfully!"
        );
        setFormData({ name: "", phone: "", message: "" });
        navigate("/");
      } else {
        toast.error(response.data.message || "Failed to submit the enquiry.");
      }
    } catch (error) {
      console.error("Error submitting the enquiry:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <div className="above-header">
        <a href="tel:+97 152 690 2530">
          <div>
            <BiPhone /> Phone: +91 9847968677
          </div>
        </a>
        <a href="mailto:kumarakomaqualillies@gmail.com">
          <div>
            {" "}
            <MdMail /> Email: kumarakomaqualillies@gmail.com
          </div>
        </a>
      </div>
      <NavigationBar />
      <div className="contact-container">
        <div className="contact-content">
          <h1>Contact Us</h1>
          <p>
            Have any questions or need more information? Fill out the form
            below, and we'll get back to you as soon as possible.
          </p>
          <form onSubmit={handleSubmit} className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number:</label>
              <input
                type="number"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="5"
                required
              />
            </div>
            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
